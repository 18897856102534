<template>
  <router-view />
</template>

<script>
// @ is an alias to /src
// import NavBar from "@/components/NavBar.vue";

export default {
  name: "HomeView",
  components: {},
};
</script>

<style lang="scss">
@import "styles/custom.scss";

@import "bootstrap/scss/bootstrap.scss";
// @import '~bootstrap-vue/src/index.scss';

body {
  font-weight: 200;
}
</style>
