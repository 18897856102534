<template>
  <div class="vh-100 outer home-featured" style="text-align: center">
    <div class="center">
      <h1>Scan mij</h1>
      <img
        src="https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=https://grijper.quantes.nl/&choe=UTF-8"
      />
      <h1>voor een</h1>
      <h1>gratis spel</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "InactiveScreen",
  components: {},
};
</script>

<style>
h1 {
  font-size: 120px;
  color: #fff;
  padding-bottom: 20px;
  font-weight: 500;
}
img {
  padding-bottom: 20px;
}
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
.screen {
  /* background: url("~@/assets/home_bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%; */
  overflow: hidden; /* Hide scrollbars */
}
.home-featured {
  background-color: #e5e5e5;
  /* background-image: linear-gradient(to top right, purple, violet, orange); */
  background: linear-gradient(#e65a2s4, #9e87aa, #efb600);
  background-size: 600% 600%;
  -webkit-animation: gradanimate 0.1s ease infinite;
  animation: gradanimate 0.1s ease infinite;
}
@-webkit-keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
@keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
</style>
