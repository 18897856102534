<template>
  <div class="vh-100 outer home-featured" style="text-align: center">
    <div class="center" v-if="getActiveGameUser.first_name">
      <h1>Hi</h1>
      <h1>{{ getActiveGameUser.first_name }},</h1>
      <h1>&nbsp;</h1>
      <h1>Let the</h1>
      <h1>game begin!</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "InactiveScreen",
  computed: {
    getActiveGameUser() {
      let activeGameUser = this.$store.getters.getActiveGameUser;
      return activeGameUser;
    },
  },
};
</script>

<style>
h1 {
  font-size: 120px;
  color: #fff;
  padding-bottom: 20px;
  /* text-transform: uppercase; */
}
img {
  padding-bottom: 20px;
}
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
.screen {
  /* background: url("~@/assets/home_bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%; */
  overflow: hidden; /* Hide scrollbars */
}
.home-featured {
  background-color: #e5e5e5;
  /* background-image: linear-gradient(to top right, purple, violet, orange); */
  background: linear-gradient(#e65a24, #9e87aa, #efb600);
  background-size: 600% 600%;
  -webkit-animation: gradanimate 0.5s ease infinite;
  animation: gradanimate 10s ease infinite;
}
@-webkit-keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
@keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
</style>
