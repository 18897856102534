<template lang="html">
  <img src="@/assets/logo.png" alt="Diensten Centrum" width="200" />
  <h1>Bedankt!</h1>
  <!-- <h2>Scan the QR code below on the claw machine for a free game.</h2> -->
  <h2>Scan de QR code op de grijpmachine en start het spel.</h2>
  <!-- <h2>Good luck!</h2> -->
  <h3>Succes!</h3>
  <div class="center">
    <img
      :src="
        'https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=' +
        // 'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=' +
        getUniqueString
        // +'&choe=UTF-8'
      "
      title="Link to Google.com"
    />
  </div>
  <button
    type="button"
    class="btn btn-primary text-light btn-lg rounded-pill"
    @click="goToUrl()"
  >
    <span>Naar svoz.nl</span>
  </button>
</template>

<script>
export default {
  name: "QrCodeScreen",
  data: () => ({}),
  computed: {
    getSavedDataId: function () {
      let savedData = this.$store.getters.getSavedData;
      if (savedData !== null) {
        return savedData._id;
      }
      return false;
    },
  },
  methods: {
    goToUrl() {
      window.location.href = "https://www.svoz.nl/";
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 30px;
  color: #fff;
}
h2 {
  font-size: 25px;
  color: #512d6d;
}
.field {
  /* text-transform: uppercase; */
  margin: auto;
  outline: none;
  border: none;
  width: 90%;
  padding: 10px;
  font-size: 40px;
  border-radius: 0px;
  text-align: center;
  margin-bottom: 20px;
}
.center {
  text-align: center;
}
</style>
